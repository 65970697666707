<template>
  <div class="d-flex" :class="[{'align-center' : centered}]">
    <div class="d-flex "
         :style="chipStyle"
         :class="[{'align-center' : centered}]"
    >
      <v-icon v-if="icon" small :color="color" class="mr-1">{{ icon }}</v-icon>
      <h5 :style="`color: ${color !== wsDARKER ? color : ''}`"
          style="font-size: 12px"
          :class="elementClass">{{ text }}</h5>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "wsChip",
  props : {
    icon : {
      type : String,
    },
    centered : {
      type : Boolean,
      default : false
    },
    text : {
      type : [String,Number],
      default : ''
    },
    color : {
      type : String,
      default : ''
    },
    bold : {
      type : Boolean,
      default : false
    },
    noWrap : {
      type : Boolean,
      default : false,
    },
    outlined : {
      type : Boolean,
      default : false,
    },
    minWidth : {
      type : String,
      default : null
    }
  },
  computed : {
    chipStyle() {
      return this.outlined ? `border-radius: 50px; border : 1px solid ${this.color};padding : 2px 8px` : ''
    },
    elementClass() {
      let item = ''

      if ( this.noWrap ) {
        item += 'text-no-wrap'
      }

      return item
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>